import React, { useState, useEffect } from "react";
import ContainerForPages from "../../../components/containerForPages";
import Container from "../../../components/container";
import { Grid } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import useTopics from "../../../hooks/useTopics"
import useTopicsByTagName from "../../../hooks/useTopicsByTagName";
import TopicAdvertWidget from "../../../components/widgets/forum/topicAdvertWidget";
import ContributingAdvisersWidget from "../../../components/widgets/forum/contributingAdvisersWidget";
import TagsWidget from "../../../components/widgets/forum/tagsWidget";
import TrendingTopicsWidget from "../../../components/widgets/forum/trendingTopicsWidget";
import TopicCard from "../../../components/forumComponents/topicComponents/topicCard"
import ForumPagination from "../../../components/forumComponents/forumPagination"
import { useLocation } from "@reach/router";
import queryString from "query-string";
import FormHandler from "../../../components/forumComponents/topicComponents/createTopicForm";
import SearchWidget from "../../../components/widgets/forum/searchWidget";
import ForumBreadcrumb from "../../../components/forumComponents/forumBreadcrumb";
import { getTopicPageBreadcrumbs } from "../../../components/forumComponents/forumBreadcrumb";
import { Helmet} from "react-helmet";
import * as uuid from 'uuid'

import { upVoteForumPost, voteForumPost } from "../../../lib/helpers";
import SEO from "../../../components/seo";

const useStyles = makeStyles((theme) => ({
  content: {
    width: "calc(100% - 370px)",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
    },
  },
  sideBar: {
    width: "300px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "20px",
      width: "100%",
      marginTop: 'initial'
    },
    marginTop: '2.1em'
  },
  topicContainer:{
    display: "flex",
    flexDirection: "column",
    "& > * + *":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "24px",
      },
    },
    "& > div:first-of-type":{
      marginBottom: "16px",
      [theme.breakpoints.up("md")]:{
        marginBottom: "24px",
      },
    },
    "& > div:last-of-type":{
      marginBottom: 0,
    },
  },
  marginBottomTemp: {
    marginBottom: "20px",
    marginTop: "29px",
  },
  advertContainer: {
    textAlign: 'center'
  }
}))
function Topics(props) {
  const { rName,isAuth } = props;
  const classes = useStyles();
  const url = typeof window !== "undefined" ? window.location.href : "";
  const location = useLocation();

  const mostRepliesQueryStringParameter = '&sort=most_posts&_=1660298528092';
  const oldestToNewestQueryStringParameter = '&sort=oldest_to_newest&_=1660298528091';
  const newestToOldestQueryStringParameter = '&sort=newest_to_oldest&_=1660298528089';
  const mostUpvotesQueryStringParameter = '&sort=most_votes&_=1660298528093';
  const mostViewsQueryStringParameter = '&sort=most_views&_=1660298528094';

  const [page, setPage] = useState(1);
  const [queryStringParameter, setQueryStringParameter] = useState(newestToOldestQueryStringParameter)

  const [topicsData, errors] = useTopics(page, rName, queryStringParameter);

  const [sortBy, setSortBy] = useState('newestToOldest');

  const [topicsDataByTagName, errorsByTagName] = useTopicsByTagName(page, queryString.parse(location.search).tag);

  const [formOpen, setFormOpen] = useState(false);
  const [topics, setTopics] = useState([]);
  const [pagination, setPagination] = useState(null);
  const [categoryId, setCategoryId] = useState(0);

  const categoryID = topicsData && topicsData.cid;

  const categoryTitle = topicsData && topicsData.title;

  const categorySlug = topicsData && topicsData.slug;

  var tagName = queryString.parse(location.search).tag;

  var auth = isAuth;
  if (typeof isAuth == "undefined") {

    if (typeof window !== 'undefined') {
      auth = (window.localStorage.getItem("isUserAuthenticated") == "true");
    }
    else {
      auth = false;
    }
  }



  useEffect(() => {
    (async () => {
      if (topicsData) {
          setCategoryId(topicsData.cid);

          setTopics(topicsData.topics);
          setPagination(topicsData.pagination);
      }
    })();
  }, [topicsData]);

  useEffect(() => {
      if (sortBy) {
        switch (sortBy) {
          case 'mostLikes': {
              setQueryStringParameter(mostUpvotesQueryStringParameter)
          } break;
          case 'newestToOldest': {
              setQueryStringParameter(newestToOldestQueryStringParameter)
          } break;
          case 'oldestToNewest': {
            setQueryStringParameter(oldestToNewestQueryStringParameter)
          } break;
          case 'mostReplies': {
            setQueryStringParameter(mostRepliesQueryStringParameter)
          } break;
          case 'mostViews': {
            setQueryStringParameter(mostViewsQueryStringParameter)
          } break;
        }
      }

  }, [sortBy]);

  const breadCrumbs = getTopicPageBreadcrumbs();

  const topicCards = topics && topics.map((topic) => {
    const { postcount, slug, tags, user, title, timestamp, timestampISO, votes, teaser, pinned } = topic;


    return (
      <TopicCard key={uuid.v4()} title={title} tags={tags} user={user} likes={votes} postcount={postcount} lastReply={teaser}
        timestampISO={teaser && teaser.timestampISO} slug={slug} pinned={pinned} pID={topic.mainPid} isAuth={auth} />
    )
  })

  const handleChange = (event, value) => {
    setPage(value)
    window.scrollTo(0, 0);
  }
  const sortByChange = (event, value) => {
    setSortBy(event.target.value);
  }

  const decodedTitle = categoryTitle && decodeURIComponent(categoryTitle);

  return (
    <ContainerForPages isCoursePage={true}>
      <SEO title={categoryTitle ? `${decodedTitle} - Boring Money` : "Boring Money"} canonical={`/forum/topics/${categorySlug}`}/>
      <Container>
        <Grid container justifyContent={"space-between"} className={classes.marginBottomTemp}>
          <Grid item className={classes.content}>
            <ForumBreadcrumb pageBreadcrumb={breadCrumbs} />

            <FormHandler formOpen={formOpen} setFormOpen={setFormOpen} categoryName={topicsData && topicsData.name} categoryID={categoryID} sortByChange={sortByChange} isAuth={auth} />
            <div className={classes.topicContainer}>
              {
                topicCards
              }
            </div>
            <ForumPagination pagination={pagination} handleChange={handleChange}/>
          </Grid>
          <Grid item className={classes.sideBar}>
            <SearchWidget />
            <div className={classes.advertContainer}>
              <TopicAdvertWidget categoryId={categoryId} />
            </div>
            <TrendingTopicsWidget />
            {/*<TagsWidget />*/}
            <ContributingAdvisersWidget />

          </Grid>
        </Grid>
      </Container>
    </ContainerForPages>
  );
}
export default Topics;
